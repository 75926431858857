<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
    :style="$vuetify.breakpoint.mdAndUp ? 'width: 25%' : 'width: 75%'"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <div style="width: 30em; text-align: center">
          <img
            src="../../assets/logo.png"
            width="150"
            v-if="$vuetify.breakpoint.lgAndDown"
          />
          <img
            src="../../assets/logo.png"
            width="250"
            v-if="!$vuetify.breakpoint.lgAndDown"
          />
          <base-material-alert v-if="done" color="primary">
            <p style="color: black">{{ $t("password_reset") }}</p>
            <a href="/?#/auth/login" style="color: black">{{
              $t("back_log_in")
            }}</a>
          </base-material-alert>
          <form
            v-if="!done"
            action
            data-vv-scope="login-form"
            @submit.prevent="validateForm('login-form')"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="password"
                    v-validate="'required|min:2'"
                    :type="passwordHidden ? 'password' : 'text'"
                    :error-messages="errors.collect('login-form.password')"
                    data-vv-validate-on="blur"
                    data-vv-name="password"
                    class="color v-text-field rounded-lg"
                    :label="$t('new_password')"
                    outlined
                  >
                    <template slot="append">
                      <v-icon
                        color="primary"
                        @click="passwordHidden = !passwordHidden"
                        >{{
                          passwordHidden ? "mdi-eye" : "mdi-eye-off"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="password_confirm"
                    v-validate="'required|min:2|is:' + password"
                    :type="passwordHidden2 ? 'password' : 'text'"
                    :error-messages="errors.collect('login-form.newPassword2')"
                    data-vv-validate-on="blur"
                    data-vv-name="newPassword2"
                    class="color v-text-field rounded-lg"
                    :label="$t('new_password2')"
                    outlined
                  >
                    <template slot="append">
                      <v-icon
                        color="primary"
                        @click="passwordHidden2 = !passwordHidden2"
                        >{{
                          passwordHidden2 ? "mdi-eye" : "mdi-eye-off"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>
                  <v-btn
                    rounded
                    block
                    large
                    color="primary"
                    depressed
                    type="submit"
                    >{{ $t("lets_go") }}</v-btn
                  >
                  <a href="/?#/auth/login">{{ $t("back_log_in") }}</a>
                </v-col>
              </v-row>
            </v-card-text>
          </form>
        </div>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import MaterialAlert from "../../components/base/MaterialAlert";
export default {
  name: "PagesLogin",

  $_veeValidate: {
    validator: "new",
  },

  components: {
    MaterialAlert,
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    email: "",
    password: "",
    password_confirm: "",
    passwordHidden: true,
    passwordHidden2: true,
    done: false,
  }),

  mounted() {
    this.$store.commit("auth/RESET_INVALID_CREDENTIALS", null, { root: true });
  },

  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async validateForm(scope) {
      const result = await this.$validator.validateAll(scope);
      if (result) {
        this.$store.commit("auth/RESET_INVALID_CREDENTIALS");
        this.resetPassword({
          email: this.$route.params.email,
          password: this.password,
          password2: this.password2,
          token: this.$route.params.token,
        }).then((response) => {
          this.done = true;
        });
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import '~vuetify/src/styles/styles.sass'
#login
  h1
    display: block
    width: 100%
    color: var(--v-primary)

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):last-child
  background-color: red
</style>